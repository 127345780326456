<template>
  <the-login :is-shown="showLogin" @close="toggleLogin"/>
  <div class="w-full border-dark-gray border-b mb-1">
    <div class="container mx-auto">
      <div class="flex justify-between py-2 gap-10">
        <a class="flex items-center gap-1 text-lg font-semibold" href="#"><span
            class=""></span><img class="h-4" src="@/assets/icons/location.svg">Донецк, ул. Постышева, 60</a>
        <a class="uppercase text-lg font-semibold" href="#">+7 (949) 501 33 35 <span
          class="text-xs normal-case">(техническая поддержка)</span></a>
        <a class="uppercase text-lg font-semibold" href="#">+7 (949) 501 33 35 <span
          class="text-xs normal-case">(торговый отдел)</span></a>
      </div>
    </div>
  </div>
  <header class="pb-5 bg-light-blue border-b border-b-dark-gray">
    <div class="container mx-auto">
      <div class="flex items-center gap-32">
        <router-link to="/">
          <img class="h-[118px]" src="@/assets/logo.svg" alt="">
        </router-link>
        <div class="w-full flex flex-col gap-1">
          <nav class="flex justify-around relative">
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-sm font-medium"
                         to="/products">Каталог
            </router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-sm font-medium"
                         to="/services">Услуги
            </router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-sm font-medium"
                         to="/support">Сервис и поддержка
            </router-link>
            <router-link class="uppercase py-6 w-full text-center hover:bg-blue hover:text-white text-sm font-medium"
                         to="/contacts">Контакты
            </router-link>
          </nav>
          <div class="flex space-x-5">
            <form class="m-0 grow">
              <label for="default-search" class="mb-2 text-sm font-regular text-dark-gray sr-only">Поиск по
                сайту</label>
              <div class="relative">
                <input type="search" id="default-search"
                       class="block pl-5 pr-12 py-3 w-full text-sm font-regular bg-light-blue text-dark-gray border border-dark-gray focus:border-blue"
                       placeholder="Поиск по сайту..." required>
                <button type="submit" class="absolute bg-blue top-[3px] right-[3px] h-10 w-10">
                  <svg class="w-5 h-5 mx-auto text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import TheLogin from "@/components/TheLogin";

export default {
  name: "TheHeader",
  components: {TheLogin},
  data() {
    return {
      showMenu: false,
      showLogin: false
    }
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
    toggleLogin: function () {
      this.showLogin = !this.showLogin;
      document.documentElement.style.overflow = this.showLogin ? 'hidden' : 'auto';
    }
  },
  watch: {}
}
</script>