<template>
  <div class="bg-white">
    <div class="container mx-auto pt-12">
      <h1 class="text-5xl">Каталог продукции</h1>

      <div class="grid grid-cols-3 py-12">
        <!-- Filters -->
        <div class="flex flex-col gap-5">
          <Listbox v-model="selectedOption">
            <div class="flex gap-5 relative">
              <ListboxLabel class="flex place-items-center"><span class="">Сортировка:</span></ListboxLabel>
              <ListboxButton
                  class="relative w-64 border border-dark-gray cursor-default bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
              >
                <span class="block truncate">{{ selectedOption.name }}</span>
                <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </span>
              </ListboxButton>
              <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
              >
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="option in options"
                      :key="option.name"
                      :value="option"
                      as="template"
                      @click="updateProducts"
                  >
                    <li
                        :class="[
                  active ? 'bg-light-blue text-blue' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
                    >
                <span
                    :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                >{{ option.name }}</span
                >
                      <span
                          v-if="selected"
                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue"
                      >
                  <CheckIcon class="h-5 w-5" aria-hidden="true"/>
                </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
          <div v-for="category in categories" :key="category.id" class="flex place-items-center gap-5">
            <input
              type="radio"
              :id="category.id"
              :value="category.id"
              v-model="selectedCategory"
              @change="updateProducts"
              class="hidden"
            />
            <label
              :for="category.id"
              :class="[
                selectedCategory === category.id ? 'bg-light-blue border border-blue text-blue' : 'text-gray-900',
                'relative cursor-pointer select-none p-2'
              ]"
            >
              {{ category.name }}
            </label>
          </div>
        </div>
        <div class="flex flex-col col-span-2 divide-y divide-dark-gray border-y border-dark-gray justify-center">
          <!-- Product item -->

          <p v-if="filteredProducts.length === 0" class="mx-auto">К сожалению, по заданным параметрам не удалось
            найти товары</p>

          <div v-for="product in filteredProducts" :key="product.id">
            <ProductItem :product="product"/>
          </div>
        </div>
        <!-- Pagination -->
        <vue-awesome-paginate
            :total-items="totalItems"
            v-model="currentPage"
            :items-per-page="perPage"
            @click="pageChanged"
            :key="paginationKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import {CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
import axios from "axios";
import ProductItem from "@/views/ProductItem.vue";
import "vue-awesome-paginate/dist/style.css";

const sortOptions = [
  {name: 'по популярности', code: 'popularity'},
  {name: 'по возрастанию стоимости', code: 'price_asc'},
  {name: 'по убыванию стоимости', code: 'price_desc'},
]
const selectedSortOption = ref(sortOptions[0])

export default {
  name: "ProductsView",
  components: {
    ProductItem,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
    SelectorIcon,
  },
  data: () => ({
    selectedOption: selectedSortOption,
    selectedCategory: null,
    options: sortOptions,
    categories: [],
    products: [],
    totalItems: 0,
    perPage: 10,
    currentPage: 1,
    paginationKey: 1
  }),
  async mounted() {
    await this.fetchCategories();
    await this.fetchProducts();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/categories');
        this.categories = response.data.categories.map(category => ({
          ...category,
          selected: false
        }));

        const categoryId = parseInt(this.$route.query.category_id, 10);
        this.selectedCategory = categoryId ? categoryId : this.categories[0].id;
      } catch (error) {
        console.error('Ошибка при загрузке списка категорий:', error);
      }
    },
    async pageChanged(page) {
      this.currentPage = page;
      await this.fetchProducts();
    },
    async fetchProducts() {
      const sortBy = this.selectedOption.code;
      const page = this.currentPage;
      const perPage = this.perPage;

      console.log('Fetching products with sort_by:', sortBy, 'and category_id:', this.selectedCategory);

      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/products', {
          params: {
            sort_by: sortBy,
            category_id: this.selectedCategory,
            page: page,
            per_page: perPage
          }
        });
        this.products = response.data.data;
        console.log(this.products);

        // Обновите общее количество элементов и другие данные пагинации
        this.totalItems = response.data.total;
        this.currentPage = response.data.current_page;
        this.perPage = response.data.per_page;
        if (this.paginationKey === 1)
          this.paginationKey++;

        this.scrollToTop();
      } catch (error) {
        console.error('Ошибка при загрузке списка товаров:', error);
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },

    async updateProducts() {
      try {
        await this.fetchProducts();
      } catch (error) {
        console.error('Ошибка при обновлении списка товаров:', error);
      }
    }
  },
  computed: {
    filteredProducts() {
      return this.products.filter(product => {
        return this.selectedCategory === product.category.id;
      });
    }
  }
}
</script>

<style scoped>

</style>
