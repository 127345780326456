<template>
  <HomeSwiper/>
  <div class="container mx-auto py-24">
    <div class="grid grid-cols-3">
      <div class="flex flex-col font-medium">
        <h2 class="text-2xl">Продуктовые группы</h2>
        <div class="space-y-4 mt-36">
          <div v-for="category in categories" :key="category.id" class="flex flex-col text-dark-gray">
            <router-link :to="`/products?category_id=${category.id}`" class="uppercase text-black hover:underline transition-all">{{ category.name }}</router-link>
          </div>
        </div>
      </div>
      <div class="col-span-2">
        <h3 class="text-5xl">Хиты продаж</h3>
        <div class="grid grid-cols-2 gap-5 mt-14">
          <div v-for="product in topProducts" :key="product.id">
            <product-item-button
              :content="product.name"
              :image="product.photos[0]"
              link="#"
            />
          </div>
        </div>
        <small-button text="Узнать подробнее" style-classes="mt-14 w-64 text-blue hover:bg-blue hover:text-white"
                      link="products"/>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="container mx-auto py-24">
      <div class="grid grid-cols-3">
        <h2 class="text-2xl font-medium">Услуги</h2>
        <div class="col-span-2">
          <h3 class="text-5xl">Мы предоставляем <span class="text-blue">оборудование и ПО для автоматизации</span>
            различных сфер бизнеса</h3>
          <div class="grid justify-between grid-cols-3 gap-10 mt-14">
            <category-button
                content="Автоматизация розничной торговли"
                link="/products"
                icon="retail.svg"
            />
            <category-button
                content="Автоматизация кафе, ресторанов, баров"
                link="/products"
                icon="horeca.svg"
            />
            <category-button
                content="Автоматизация склада"
                link="/products"
                icon="stock.svg"
            />
            <category-button
                content="Видеонаблюдение"
                link="/services"
                icon="retail.svg"
            />
            <category-button
                content="Система Контроля Управления Доступом"
                link="/services"
                icon="retail.svg"
            />
            <category-button
                content="Техническая поддержка"
                link="/services"
                icon="retail.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-24 grid grid-cols-3">
    <div>
      <h2 class="text-2xl font-medium">Новости</h2>
    </div>
    <div class="col-span-2">
      <h3 class="text-5xl">Будьте в курсе <span class="text-blue">событий из жизни компании</span></h3>
      <div class="flex flex-col divide-y divide-dark-gray border-y border-dark-gray mt-14">
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image3.png" alt="">
        </div>
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image4.png" alt="">
        </div>
        <div class="grid grid-cols-2 h-96 gap-10 py-10">
          <div class="flex flex-col justify-between">
            <div class="space-y-2">
              <p class="text-sm font-medium text-dark-gray">23 марта 2022</p>
              <p class="text-2xl font-medium">Заголовок новости</p>
              <p class=" text-dark-gray">Pretium, iaculis mauris, interdum sagittis. Fermentum ut
                morbi commodo dignissim. Amet,
                sem cras nec at. Consequat sed morbi commodo proin turpis. Id quisque purus nisi est
                purus justo, egestas. Sagittis enim at adipiscing massa sed erat sit.</p>
            </div>
            <a class="underline-offset-4 hover:underline text-blue uppercase transition-all" href="#">Читать далее</a>
          </div>
          <img class="h-full w-full object-cover" src="@/assets/image5.png" alt="">
        </div>
      </div>
      <div class="flex mt-14 gap-5">
        <small-button text="Все новости" link="#"
                      style-classes="text-white px-10 bg-blue hover:bg-white hover:text-blue"/>
        <small-button text="Подписаться на рассылку" link="#"
                      style-classes="px-10 text-blue hover:bg-blue hover:text-white"/>
      </div>
    </div>
  </div>
</template>

<script>
import HomeSwiper from '@/components/swiper/HomeSwiper.vue';
import SmallButton from '@/components/buttons/SmallButton';
import CategoryButton from "@/components/buttons/CategoryButton";
import ProductItemButton from "@/components/buttons/ProductItemButton";
import axios from "axios";

export default {
  name: 'HomeView',
  components: {
    SmallButton,
    HomeSwiper,
    CategoryButton,
    ProductItemButton
  },
  data: () => ({
    topProducts: [],
    categories: [],
  }),
  async mounted() {
    await this.fetchTopProducts();
    await this.fetchCategories();
  },
  methods: {
    async fetchTopProducts() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/products/top');
        this.topProducts = response.data.data;
      } catch (error) {
        console.error('Ошибка при загрузке списка топ-товаров:', error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get('https://api.bazis.zk-digital.ru/api/categories');
        this.categories = response.data.categories.map(category => ({
          ...category,
          selected: false
        }));
        this.selectedCategory = this.categories[0].id;
      } catch (error) {
        console.error('Ошибка при загрузке списка категорий:', error);
      }
    },
  }
}
</script>
