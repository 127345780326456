<template>
  <div class="grid grid-cols-3 gap-5 py-10">
    <img :src="product.preview" :alt="product.name">
    <div class="flex flex-col gap-5 col-span-2">
      <h2 class="text-3xl">{{ product.name }}</h2>

      <div class="flex gap-2 text-right text-xs text-blue uppercase">
        <p v-for="group in product.groups" :key="group" class="border p-2 border-blue">{{ group.name }}</p>
      </div>

      <button @click="toggleDescription" class="p-2 text-xs uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
        {{ showDescription ? 'Скрыть характеристики' : 'Показать характеристики' }}
      </button>
      <div v-if="showDescription" v-html="product.description"></div>

      <ul class="list-inside list-disc">
        <li v-for="feature in product.features" :key="feature">{{ feature }}</li>
      </ul>
      <p class="text-2xl text-blue font-medium">{{ formatPrice(product.price) }}</p>
      <button class="p-2 uppercase border w-64 border-blue text-blue hover:bg-blue hover:text-white transition-all">
        Купить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDescription: false
    };
  },
  methods: {
    toggleDescription() {
      this.showDescription = !this.showDescription;
    },
    formatPrice(priceString) {
      const formattedIntegerPart = parseInt(priceString).toLocaleString();
      return `${formattedIntegerPart}₽`;
    }
  }
}
</script>

<style scoped>
</style>
