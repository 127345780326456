<template>
  <div class="flex flex-col items-start">
    <p class="text-sm font-semibold uppercase">{{ subtitle }}</p>
    <h3 class="text-blue text-6xl 2xl:text-7xl leading-none font-medium uppercase mt-3 mb-6">{{ title }}</h3>
    <p class="text-lg leading-tight font-medium">{{ description }}</p>
    <ul class="list-disc marker:text-blue list-inside mt-5">
      <!-- Используем v-for для создания элементов списка из массива строк -->
      <li v-for="(item, index) in items" :key="index">{{ item }}</li>
    </ul>
    <small-button content="Узнать подробнее" styleClasses="w-56 text-blue hover:bg-blue hover:text-white mt-7" :link="link"/>
  </div>
</template>

<script>
import SmallButton from "@/components/buttons/SmallButton";

export default {
  name: "SwiperItem",
  components: {SmallButton},
  props: {
    title: String,
    subtitle: String,
    description: String,
    link: String,
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
