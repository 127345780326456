<template>
  <div class="bg-light-blue border-b border-b-dark-gray">
    <div class="container mx-auto grid grid-cols-3 divide-x divide-dark-gray">
      <div class="my-12 mr-12 w-100">
        <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="0"
            :pagination=pagination
            @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(slide, index) in slides" :key="index">
            <!-- Передача свойства items в SwiperItem -->
            <swiper-item :title="slide.title" :subtitle="slide.subtitle" :link="slide.link" :description="slide.description"  :items="slide.items"/>
          </swiper-slide>

          <div class="flex gap-10 mt-10">
            <SwiperPrevButton/>
            <div class="swiper-pagination flex gap-2"></div>
            <SwiperNextButton/>
          </div>
        </swiper>
      </div>
      <div class="col-span-2">
        <div class="flex flex-col divide-y divide-dark-gray">
          <div class="my-12 ml-12">
            <img class="w-full object-cover" v-if="currentSlideImage" :src="currentSlideImage" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperItem from "@/components/swiper/SwiperItem";
import 'swiper/css';
import SwiperNextButton from "@/components/swiper/SwiperNextButton";
import SwiperPrevButton from "@/components/swiper/SwiperPrevButton";
import axios from "axios";

export default {
  name: "HomeSwiper",
  components: {
    SwiperPrevButton,
    SwiperNextButton,
    SwiperItem,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      slides: [],
      currentSlideIndex: 0
    };
  },
  async created() {
    try {
      const response = await axios.get('https://api.bazis.zk-digital.ru/api/slides');
      this.slides = response.data.slides;
    } catch (error) {
      console.error('Error fetching slides:', error);
    }
  },
  computed: {
    currentSlideImage() {
      if (this.slides.length > 0 && this.slides[this.currentSlideIndex]) {
        return this.slides[this.currentSlideIndex].image;
      } else {
        // Если массив slides пустой или текущий индекс недействителен, вернуть пустую строку или другое значение по умолчанию
        return '';
      }
    }
  },
  setup() {
    const onSlideChange = () => {

    };
    return {
      onSlideChange,
      modules: [Pagination],
      pagination: {
        clickable: true,
        el: '.swiper-pagination',
        renderBullet: function (index, className) {
          return '<span class="' + className + ' w-2 h-2 bg-dark-gray rounded-full cursor-pointer"></span>';
        }
      }
    };
  }
}
</script>

<style>
.swiper-pagination-bullet-active {
  background: rgb(0 95 143) !important;
}
</style>
