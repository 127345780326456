<template>
  <div class="bg-white">
    <div class="container mx-auto py-12">
      <h1 class="text-5xl">{{ serviceItem.title }}</h1>
      <div class="flex flex-col divide-y divide-dark-gray border-y border-dark-gray mt-14 py-2">
        <div class="" v-html="serviceItem.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ServiceView",
  data() {
    return {
      serviceItem: '',
    };
  },
  mounted() {
    this.fetchserviceItem();
  },
  methods: {
    fetchserviceItem() {
      axios.get(`https://api.bazis.zk-digital.ru/api/pages/${this.$route.path.split('/')[1]}`)
          .then(response => {
            this.serviceItem = response.data.page;
          })
          .catch(error => {
            console.error('Error fetching service item:', error);
          });
    },
  }
}
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
  margin-top: 48px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #005F8F;
  border: 1px solid #005F8F;
  color: white;
}

.active-page:hover {
  background-color: #005F8F;
}
</style>
