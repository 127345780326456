<template>
  <div @keydown.esc="$emit('close')" v-show="isShown"
       class="absolute flex place-content-center items-center top-0 bottom-0 h-screen w-full transition-all">
    <div class="absolute w-96 bg-white flex flex-col items-end gap-5 px-5 pt-5 pb-10 z-50">
      <button @click="$emit('close')" class="h-3 w-3"><img src="@/assets/icons/cross.svg" alt="Закрыть"></button>
      <p class="mx-auto font-medium text-2xl">Вход</p>
      <div class="flex flex-col gap-2 w-full">
        <input ref="login" class="w-full text-dark-gray border border-dark-gray px-4 py-2" type="text"
               placeholder="Логин">
        <input ref="password" class="w-full text-dark-gray border border-dark-gray px-4 py-2" type="password"
               placeholder="Пароль">
      </div>
      <button class="uppercase bg-blue text-white text-xs py-5 font-semibold w-48 mx-auto">Войти</button>
    </div>
    <div class="h-full w-full bg-black opacity-50 absolute z-40 overscroll-none"/>
  </div>
</template>

<script>
export default {
  name: "TheLogin",
  props: {
    isShown: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
}
</script>