<template>
  <div class="bg-white">
    <div class="container mx-auto py-12">
      <h1 class="text-5xl">{{ newsItem.title }}</h1>
      <h1 class="text-sm text-gray-500">от {{ formatDate(newsItem.created_at) }}</h1>
      <div class="flex flex-col divide-y divide-dark-gray border-y border-dark-gray mt-14 py-2">
        <div class="" v-html="newsItem.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "NewsItemView",
  data() {
    return {
      newsItem: '',
    };
  },
  mounted() {
    this.fetchNewsItem();
  },
  methods: {
    fetchNewsItem() {
      axios.get(`https://api.bazis.zk-digital.ru/api/news/${this.$route.params.id}`)
          .then(response => {
            this.newsItem = response.data.news;
          })
          .catch(error => {
            console.error('Error fetching news item:', error);
          });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style>
.pagination-container {
  display: flex;
  column-gap: 10px;
  margin-top: 48px;
}

.paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}

.paginate-buttons:hover {
  background-color: #d8d8d8;
}

.active-page {
  background-color: #005F8F;
  border: 1px solid #005F8F;
  color: white;
}

.active-page:hover {
  background-color: #005F8F;
}
</style>
